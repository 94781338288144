<template>
  <div v-if="getCalendar" class="p-mt-1 p-ml-0">
    <slot name="title">
      <div class="p-card-title">
        <div class="p-d-flex p-jc-between">
          <div><h3 class="p-m-0"><strong>{{ getCalendar.name }}</strong></h3></div>
        </div>
      </div>
    </slot>
    <slot name="configurator">
      <div class="p-py-0 p-my-0">
        <div class="p-grid">
          <div class="p-col-5" style="vertical-align: middle;">
            <p>{{ _t('label_object_name') }}</p>
          </div>
          <div class="p-col-7" style="vertical-align: middle;">
            <p><strong>{{ getCalendar.name ?? _t('_NONE') }}</strong></p>
          </div>
        </div>
      </div>
    </slot>
    <slot name="calendar">
      <div class="sc-wrapper">
        <custom-full-calendar
            v-if="renderCalendar"
            v-model:date="date"
            :events="events"
            :holidays="getHolidays"
            :options="calendarOptions"
            :type="calendarType"
            :view="calendarView"
            :viewTypes="calendarViewTypes"
            @click-event="clickEvent"
        />
      </div>
    </slot>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as moment from 'moment-timezone';
import CustomFullCalendar from '@/components/CustomFullCalendar';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import _ from 'lodash';

export default {
  name: 'alarm-point-calendar-configurator',
  components: {
    'custom-full-calendar': CustomFullCalendar,
  },
  props: {
    orgId: {
      type: String,
      default: "1",
      required: true,
    },
  },
  data: () => ({
    checked: false,
    selectedAlarmPlan: null,
    date: moment(),
    events: [],
    firstRun: true,
    calendarView: ViewEnum.TIMELINE,
    calendarType: ViewTypeEnum.MONTH,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => ![ViewTypeEnum.YEAR].includes(i)).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    invalidEvents: [],
    calendarOptions: {
      dow: 1,
    },
    renderCalendar: true,
  }),
  created() {
    this.moment = moment;
    this.moment.updateLocale('en', {
      week: {
        dow: 1,
      }
    });
  },
  async mounted() {
    await this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    this.generateEvents();
  },
  methods: {
    ...mapActions({
      'callCalendar': 'alarmpoints/callCalendar',
      'callGetHolidays': 'holidays/callGetHolidays',
    }),
    ...mapMutations({
      'setCalendar': 'alarmpoints/setCalendar',
      'createEmptyEvent': 'alarmpoints/createEmptyEvent',
    }),
    generateEvents: function () {
      if (!this.getEvents) return [];
      let events = [];
      const getEvents = JSON.parse(JSON.stringify(this.getEvents));

      const eventGroups = _.groupBy(getEvents, i => i.sourceEventId);
      for (const eventGroupsKey in eventGroups) {
        let times = [];
        let title = '';
        for (const eventKey in eventGroups[eventGroupsKey]) {
          title = eventGroups[eventGroupsKey][eventKey].title;
          const timesItem = {
            start: moment(eventGroups[eventGroupsKey][eventKey].day, 'YYYY-MM-DD').set({
              hours: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').hours() ?? '00',
              minutes: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').minutes() ?? '00',
              seconds: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').seconds() ?? '00',
            }).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(eventGroups[eventGroupsKey][eventKey].day, 'YYYY-MM-DD').set({
              hours: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').hours() ?? '59',
              minutes: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').minutes() ?? '59',
              seconds: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').seconds() ?? '59',
            }).format('YYYY-MM-DD HH:mm:ss'),
          }
          times.push(timesItem);
        }

        if (times.length) {
          let event = events.find(i => i.sourceEventId === eventGroupsKey) ?? null;
          if (event) {
            event.times = event.times.concat(times);
          } else {
            event = {
              id: eventGroupsKey,
              title,
              times,
            };
            if (!events.includes(event)) {
              events.push(event);
            }
          }
        }
      }
      this.events = events;
      if (this.firstRun) {
        // This is a fix, do not delete this block
        this.firstRun = false;
        this.date = moment();
      }

      // this.validateWeeks();
      return events;
    },
    forceRerender() {
      this.renderCalendar = false;
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
    clickEvent(event) {
      this.$emit('clickEvent', event);
    }
  },
  computed: {
    ...mapGetters({
      'getCalendar': 'alarmpoints/getCalendar',
      'getEvents': 'alarmpoints/getEvents',
      'getHolidays': 'holidays/getHolidays',
    }),
  },
  watch: {
    getEvents: function () {
      this.generateEvents();
    },
    date: function () {
      this.generateEvents();
      this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()})
    },
  },
}
</script>

<style scoped>
:deep(.sc-calendar__header) {
  background-color: #d6ddd87d;
}

:deep(.invalid) {
  background-color: #D32F2F42;
}

:deep(.p-inputmask.p-inputtext.p-component.p-filled) {
  width: 80%;
}
</style>
