<template>
  <div class="p-d-flex p-flex-row flex-grow-1">
    <div class="p-d-inline-flex flex-grow-1 p-flex-column triggers-list" style="width: 100%">
      <div class="p-d-flex p-flex-row flex-grow-1 p-mb-3">
        <div class="p-d-block flex-grow-1" style="width: 250px">
          <strong style="font-size: 0.9rem;">{{ _t('label_Available_triggers') }}:</strong>
        </div>
        <div class="p-d-inline-flex flex-grow-1" style="width: 100%">
          <strong style="font-size: 0.9rem;">{{ _t('label_Assigned_triggers') }}:</strong>
        </div>
      </div>

      <div v-for="(item, index) in getTriggers" :key="index"
           :class="{ 'stripedBg': index % 2 === 0 }"
           class="p-d-flex p-flex-row flex-grow-1 triggers-list__item p-ai-center"
           style="width: 100%"
      >
        <div class="p-d-block flex-grow-1" style="width: 250px; font-size: 0.8rem">
          {{ _t(item.name) }}
        </div>
        <div class="p-d-block flex-grow-1" style="width: 100%">
          <template v-if="item.trigger">
            <Button :label="loadingButton ? _t('label_loading'): item.trigger.name"
                    :loading="loadingButton"
                    class="p-button-success p-button-text p-text-bold"
                    @click="onLoadTrigger(item.trigger.id)"
            />
            <Button v-if="isEditable"
                    :loading="loadingButton"
                    class="p-button-danger p-button-text p-button-rounded p-button-sm" icon="pi pi-times"
                    @click="onRemoveTrigger(item.trigger.id)"
            />
          </template>
          <Button v-else-if="isEditable"
                  :label="_t('Create')" :loading="loadingButton"
                  class="p-button-success p-button-text"
                  @click="onCreateTrigger(item.name)"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'follow-up-triggers-list',
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
    trigger: {
      type: [Object, null],
      default: () => null,
    },
    initiatorId: {
      type: [Number, String, null],
      default: () => null,
    },
  },
  data: () => ({
    loadingButton: false,
  }),
  mounted() {

  },
  methods: {
    ...mapActions({
      'callAvailableTriggers': 'consequentials/callAvailableTriggers',
      'callList': 'consequentials/callList',
      'callAlias': 'consequentials/callAlias',
      'callItem': 'consequentials/callItem',
      'callRemove': 'consequentials/callRemove',
    }),
    ...mapMutations({
      'setEmptyTrigger': 'consequentials/setEmptyTrigger',
      'setMode': 'consequentials/setMode',
      'setTriggerType': 'consequentials/setTriggerType',
    }),
    onCreateTrigger(type) {
      this.loadingButton = true;
      this.setMode('new');
      this.setTriggerType(type);
      this.callAlias(type)
          .then(() => {
            this.setEmptyTrigger();
          })
          .finally(() => {
            this.loadingButton = false;
          });
    },
    onLoadTrigger(triggerId) {
      this.loadingButton = true;
      this.setMode('update');
      this.callItem(triggerId)
          .finally(() => {
            this.loadingButton = false;
          });
    },
    onRemoveTrigger(triggerId) {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.loadingButton = true;
          this.callRemove(triggerId)
              .finally(() => {
                this.loadingButton = false;
              });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      'getAvailableTriggers': 'consequentials/getAvailableTriggers',
      'getTriggers': 'consequentials/getTriggers',
    }),
    selectedTrigger: {
      get() {
        return this.trigger
      },
      set(val) {
        this.$emit('update:trigger', val)
      },
    },
  }
}
</script>

<style scoped>
.triggers-list__item {
  min-height: 33px;
}

.triggers-list__item:hover {
  background-color: rgb(207 227 183);
  cursor: pointer;
}
</style>
