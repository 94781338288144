<template>
  <div class="p-grid">
    <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
      <div><i class="pi pi-calendar"></i> <strong>{{ _t('label_regular_calendar') }}</strong>
        <Button
            v-if="isEditable && getCalendar"
            class="p-button-danger"
            label="Unassign"
            style="float: right"
            @click="onUnAssignRotationalCalendar"
        />
      </div>
    </div>
  </div>
  <CalendarConfigurator v-if="getCalendar" v-model:orgId="selectedOrg"></CalendarConfigurator>
  <template v-else-if="isEditable">
    <div class="p-grid">
      <div class="p-col-3 p-mt-2">
        {{ _t('label_assign_existing_schedule') }}
      </div>
      <div class="p-col p-mt-2">
        <Dropdown v-model="tempRotationalCalendar" :options="getRotationalCalendars"
                  :placeholder="_t('Select')" optionLabel="name"
                  style="min-width: 400px; width: 100%"
        />
      </div>
      <div class="p-col-3 p-mt-2">
        <Button :disabled="!tempRotationalCalendar.id" :label="_t('label_assign')" class="p-button-secondary"
                @click="onAssignRotationalCalendar"/>
      </div>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-3">
        {{ _t('label_create') }}
      </div>
      <div class="p-col p-d-flex">
        <InputText v-model="tempRotationalCalendar.name" class="p-d-inline-flex" placeholder="Name" style="width: 100%"
                   type="text"/>
        <InputText v-model="tempRotationalCalendar.description" class="p-d-inline-flex" placeholder="Description"
                   style="width: 100%" type="text"/>
        <Calendar v-model="tempRotationalCalendar.validFrom" class="p-d-inline-flex" dateFormat="dd.mm.yy"
                  style="width: 100%"/>
      </div>
      <div class="p-col-3">
        <Button :disabled="!tempRotationalCalendar" class="p-button-secondary"
                @click="onCreateRotationalCalendar">{{_t('label_create')}}
        </Button>
      </div>
    </div>
  </template>
  <template v-else>No schedule assigned</template>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CalendarConfigurator from '../../calendar-configurator';
import moment from "moment-timezone";

export default {
  name: 'follow-up-schedules-tab',
  components: {
    CalendarConfigurator,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
    orgId: {
      type: [Number, String, null],
      default: () => null,
    },
  },
  data: () => ({
    tempRotationalCalendar: {
      id: null,
      name: '',
      description: '',
      validFrom: moment().format('DD.MM.YYYY'),
    },
  }),
  methods: {
    ...mapActions({
      'callCalendarById': 'rotationalCalendar/callCalendarById',
      'createRotaionalCalendar': 'rotationalCalendar/callCreateCalendar',
    }),
    ...mapMutations({
      'createEmptyCalendar': 'rotationalCalendar/createEmptyCalendar',
      'setCalendar': 'rotationalCalendar/setCalendar',
    }),
    clearCalendarForm() {
      this.tempRotationalCalendar = {
        id: null,
        name: '',
        description: '',
        validFrom: moment().format('DD.MM.YYYY'),
      };
    },
    onCreateRotationalCalendar() {
      this.createEmptyCalendar({orgId: this.orgId});
      this.getCalendar.name = this.tempRotationalCalendar.name;
      this.getCalendar.description = this.tempRotationalCalendar.description;
      this.getCalendar.validFrom = moment(this.tempRotationalCalendar.validFrom, 'DD.MM.YYYY').toDate();
      this.createRotaionalCalendar()
          .then(() => {
            if (this.getCalendar.id) {
              this.getTrigger.rotationalCalendarId = this.getCalendar.id ?? null;
            }
            this.clearCalendarForm();
          });
    },
    onAssignRotationalCalendar() {
      this.getTrigger.rotationalCalendarId = this.tempRotationalCalendar.id ?? null;
      this.callCalendarById(this.tempRotationalCalendar.id ?? null);
      this.clearCalendarForm();
    },
    onUnAssignRotationalCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.getTrigger.rotationalCalendarId = null;
          this.setCalendar(null);
        },
        reject: () => {
        }
      });
      this.clearCalendarForm();
    },
  },
  computed: {
    ...mapGetters({
      'getTrigger': 'consequentials/getTrigger',
      'getRotationalCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
    }),
    selectedOrg: {
      get() {
        return this.orgId;
      },
      set(val) {
        this.$emit('update:orgId', val);
      },
    }
  }
}
</script>
