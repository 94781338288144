<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h3 class="subhead">{{ _t('label_more_notifications_settings') }}</h3>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_confirm_mode') }}<sup>*</sup></p>
    </div>
    <div class="p-col-2 stripedBg" style="vertical-align: middle;">
      <Dropdown
          v-if="isEditable"
          v-model="getTrigger.acknowledgeMode"
          :options="confirmationModes"
          class="p-m-0"
          optionLabel="label"
          optionValue="value"
          style="width: 100%;"/>
      <p v-else><strong>
        <SelectedOption
            v-model="getTrigger.acknowledgeMode"
            :options="confirmationModes"
            optionLabel="label"
            optionValue="value"/>
      </strong></p>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p :class="!isEditable ||  ['NONE','MEDIA'].includes(getTrigger.acknowledgeMode) ? 'disabled' : ''">
        {{ _t('label_confirm_time') }}<sup>*</sup></p>
    </div>
    <div class="p-col-2 stripedBg" style="vertical-align: middle;">
      <InputText
          v-if="isEditable"
          v-model="getTrigger.localAcknowledgeTime"
          :disabled="['NONE','MEDIA'].includes(getTrigger.acknowledgeMode)"
          class="p-m-0"
          min="0"
          style="width:100%;"
          type="number"
      />
      <p v-else
         :class="['NONE','MEDIA'].includes(getTrigger.acknowledgeMode) ? 'disabled' : ''">
        <strong>{{ getTrigger.localAcknowledgeTime }}</strong>
      </p>
    </div>
    <div class="p-col-2 stripedBg" style="vertical-align: middle;">
      <p :class="!isEditable || ['NONE','MEDIA'].includes(getTrigger.acknowledgeMode) ? 'disabled' : ''">
        {{ _t('label_minutes') }}</p>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_media_usage_manual') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Dropdown v-if="isEditable"
                v-model="getTrigger.mediaUsageType"
                :options="mediaUsageTypes"
                class="p-m-0"
                optionLabel="label"
                optionValue="value"
                style="width: 100%;"/>
      <p v-else><strong>
        <SelectedOption v-model="getTrigger.mediaUsageType"
                        :options="mediaUsageTypes"
                        optionLabel="label"
                        optionValue="value"/>
      </strong></p>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_media_usage') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <Dropdown v-if="isEditable"
                v-model="getTrigger.mediaProcessingType"
                :options="mediaProcessingTypes"
                class="p-m-0"
                optionLabel="label"
                optionValue="value"
                style="width: 100%;"/>
      <p v-else><strong>
        <SelectedOption v-model="getTrigger.mediaProcessingType"
                        :options="mediaProcessingTypes"
                        optionLabel="label"
                        optionValue="value"/>
      </strong></p>
    </div>
    <div class="p-col-12">
      <h3 class="subhead p-mt-4">{{ _t('label_more_alarm_settings') }}</h3>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_speech_text') }}</p>
      <Button v-if="isEditable" class="p-button p-button-secondary p-mt-4"
              :label="_t('label_copy_from_alarmtext')"
              @click="getTrigger.speechText = getTrigger.text"/>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Textarea v-if="isEditable"
                v-model="getTrigger.speechText"
                :placeholder="_t('label_speech_text')"
                class="p-m-0"
                rows="5"
                style="width:100%"/>
      <p v-else><strong>{{ getTrigger.speechText }}</strong></p>
    </div>
    <!--    <div class="p-col-3 stripedBg" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_rep_mon_interval') }}</p>-->
    <!--    </div>-->
    <!--    <div class="p-col-2 stripedBg" style="vertical-align: middle;">-->
    <!--      <Checkbox v-model="repeatableMonitored"-->
    <!--                :binary="true"-->
    <!--                :disabled="!isEditable"-->
    <!--                class="p-m-0"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div class="p-col-2 stripedBg" style="vertical-align: middle;">-->
    <!--      <InputText v-if="isEditable"-->
    <!--                 v-model="getTrigger.repeatMonInterval"-->
    <!--                 :disabled="!repeatableMonitored"-->
    <!--                 class="p-m-0"-->
    <!--                 :min="repeatableMonitored ? 1 : 0"-->
    <!--                 style="width:100%"-->
    <!--                 type="number"/>-->
    <!--      <p v-else><strong>{{ getTrigger.repeatMonInterval }}</strong></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-5 stripedBg" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_minutes') }}</p>-->
    <!--    </div>-->
    <!--    <div class="p-col-3" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_triggering') }}<sup>*</sup></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-9" style="vertical-align: middle;">-->
    <!--      <Dropdown v-if="isEditable"-->
    <!--                v-model="getTrigger.triggering"-->
    <!--                :options="triggerings"-->
    <!--                class="p-m-0"-->
    <!--                optionLabel="label"-->
    <!--                optionValue="value"-->
    <!--                style="width: 100%;"/>-->
    <!--      <p v-else><strong>-->
    <!--        <SelectedOption v-model="getTrigger.triggering"-->
    <!--                        :options="triggerings"-->
    <!--                        optionLabel="label"-->
    <!--                        optionValue="value"/>-->
    <!--      </strong></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-3 stripedBg" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_number_of_repeats') }}<sup>*</sup></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-2 stripedBg" style="vertical-align: middle;">-->
    <!--      <InputText v-if="isEditable"-->
    <!--                 v-model="getTrigger.countRepeat"-->
    <!--                 class="p-m-0"-->
    <!--                 min="0"-->
    <!--                 style="width:100%"-->
    <!--                 type="number"/>-->
    <!--      <p v-else><strong>{{ getTrigger.countRepeat }}</strong></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-3 stripedBg" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_between_repeats') }}<sup>*</sup></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-2 stripedBg" style="vertical-align: middle;">-->
    <!--      <InputText v-if="isEditable"-->
    <!--                 v-model="getTrigger.repeatBetween"-->
    <!--                 class="p-m-0"-->
    <!--                 min="0"-->
    <!--                 style="width:100%"-->
    <!--                 type="number"/>-->
    <!--      <p v-else><strong>{{ getTrigger.repeatBetween }}</strong></p>-->
    <!--    </div>-->
    <!--    <div class="p-col-2 stripedBg" style="vertical-align: middle;">-->
    <!--      <p>{{ _t('label_minutes') }}</p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SelectedOption from '@/components/ixarma/SelectedOption'

export default {
  name: 'follow-up-advanced-tab',
  components: {
    SelectedOption,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    confirmationModes: [],
    mediaUsageTypes: [],
    mediaProcessingTypes: [],
    repeatableMonitoredState: false,
    triggerings: [],
  }),
  mounted() {
    this.mediaProcessingTypes = [
      {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
      {value: 'BY_RANK', label: this._t('BY_RANK')}
    ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
  },
  methods: {},
  computed: {
    ...mapGetters({
      'getTrigger': 'consequentials/getTrigger',
    }),
    repeatableMonitored: {
      get() {
        return this.repeatableMonitoredState;
      },
      set(val) {
        this.repeatableMonitoredState = val;
        if (this.repeatableMonitored) {
          this.getTrigger.countRepeat = 0
          this.getTrigger.repeatBetween = 0
          this.getTrigger.repeatMonInterval = 1
        } else {
          this.getTrigger.repeatMonInterval = 0
        }
      }
    }
  }
}
</script>
