<template>
  <div class="p-grid">
    <div v-if="srcOptions > 0" class="p-col-3" style="vertical-align: middle;">
      <div v-if="isEditable">
        <div v-for="(option, i) in srcOptions" :key="option" class="p-field-radiobutton">
          <RadioButton :id="'srcOption'+i"
                       v-model="srcOption"
                       :disabled="!isEditable"
                       :value="option.value"
                       name="srcOption"
                       @change="syncSrcOptions"
          />
          <label :for="'srcOption'+i">{{ option.label }}</label>
        </div>
      </div>
      <p v-else>
        <SelectedOption v-model="srcOption" :options="srcOptions" optionLabel="label" optionValue="value"/>
      </p>
    </div>
    <div v-if="srcOption=='EXIST'" :class="srcOptions > 0 ? 'p-col-9' : 'p-col-12'" style="vertical-align: middle;">
      <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-exclamation-triangle"></i>
                    </span>
        <Dropdown v-model="getTrigger.alarmPlanId"
                  :filter="true"
                  :options="getAlarmPlans"
                  :placeholder="_t('label_Select_plan')"
                  class="p-m-0"
                  optionLabel="name"
                  optionValue="id"
                  style="width:100%;"
                  @change="getAlarmPlan($event.value)"
        />
        <span class="p-inputgroup-addon">
                      <i v-tooltip.bottom="_t('Clear')"
                         class="pi pi-times"
                         @click="selectedAlarmPlan = {id: null}; getTrigger.alarmPlanId = null"></i>
                    </span>
      </div>
      <p v-else><strong>
        <SelectedOption v-model="selectedAlarmPlan.id" :options="getAlarmPlans" optionLabel="name"
                        optionValue="id"/>
      </strong></p>
    </div>
    <div v-if="srcOption=='NEW'" class="p-col-9" style="vertical-align: middle;">
      <div class="p-grid">
        <div class="p-col-4 stripedBg">
          <p>{{ _t('label_alarm_plan') }}<sup>*</sup></p>
        </div>
        <div class="p-col-8 stripedBg">
          <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-pencil"></i>
                        </span>
            <InputText v-model="selectedAlarmPlan.name" :placeholder="_t('label_alarm_plan')"
                       class="p-m-0"
                       style="width:100%;"/>
          </div>
        </div>
        <div class="p-col-4">
          <p>{{ _t('label_description') }}</p>
        </div>
        <div class="p-col-8">
          <div class="p-inputgroup p-mt-2">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi pi-pencil"></i>
                        </span>
            <InputText v-model="selectedAlarmPlan.description" :placeholder="_t('label_description')"
                       class="p-m-0" style="width:100%;"/>
          </div>
        </div>
        <div class="p-col-4 stripedBg">
          <p>{{ _t('label_organization') }}</p>
        </div>
        <div class="p-col-8 stripedBg">
          <OrganizationLink v-model="selectedAlarmPlan" :editMode="isEditable"/>
        </div>
      </div>
    </div>
  </div>
  <h3 class="subhead">{{ _t('label_Target_groups') }}</h3>
  <TabView v-model:activeIndex="activeTabSimple">
    <TabPanel :header="_t('label_Standard_view')">
      <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
           class="p-grid">
        <Card
            v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
            :key="callGroup"
            class="p-mr-2 p-mb-2"
            style="display:inline-block; width:20rem; font-size: 12px;"
        >
          <template #content>
            <div class="p-grid">
              <div class="p-col-9">
                <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi-pencil"></i>
                      </span>
                  <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                             :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                </div>
                <p v-else class="color-green"><strong>{{
                    selectedAlarmPlan.callGroups[index].name
                    && selectedAlarmPlan.callGroups[index].name.length ? selectedAlarmPlan.callGroups[index].name : '[...]'
                  }}</strong></p>
              </div>
              <div class="p-col-3 p-text-right">
                <Button v-if="isEditable && srcOption != 'EXIST'" class="p-button-danger"
                        @click="initCallGroupDeleteDialog(index)">
                  <i class="pi pi-trash"></i>
                </Button>
              </div>
              <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                {{ _t('label_notification_method') }}
              </div>
              <div
                  :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                          v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                          :options="callRules"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width:100%;" @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                />
                <strong v-else>
                  <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRule" :options="callRules"
                                  default="..."
                                  optionLabel="label"
                                  optionValue="value"/>
                </strong>
              </div>
              <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                <span>{{ _t('label_Number_required_ack') }}</span>
              </div>
              <div
                  :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                <InputText v-if="isEditable && srcOption != 'EXIST'"
                           v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                           class="p-m-0" min="0"
                           style="width: 100%;" type="number"
                />
                <strong v-else>{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>
              </div>
              <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                {{ _t('label_Method_participants_selection') }}
              </div>
              <div
                  :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                          v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                          :options="selectRules"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width:100%;" @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                />
                <strong v-else>
                  <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberSelectRule" :options="selectRules"
                                  default="..."
                                  optionLabel="label"
                                  optionValue="value"/>
                </strong>
              </div>
              <div class="p-col-8" style="vertical-align: middle;">
                <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
              </div>
              <div class="p-col-4 p-text-right">
                  <span v-if="isEditable && srcOption != 'EXIST'" class="p-buttonset">
                    <Button v-tooltip.bottom="_t('Edit')"
                            class="p-button-success p-button-icon-only p-button-text"
                            @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                      <i class="pi pi-plus-circle"></i>
                    </Button>
                    <Button
                        v-tooltip.bottom="_t('Delete')"
                        :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                        class="p-button-danger p-button-icon-only p-button-text"
                        @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                      <i class="pi pi-minus-circle"></i>
                    </Button>
                  </span>
              </div>
              <div class="p-col-12">
                <DataTable
                    v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                    :disabled="!isEditable || srcOption == 'EXIST'" :scrollable="true"
                    :value="selectedAlarmPlan.callGroups[index].members"
                    class="p-m-0 condensed"
                    dataKey="id"
                    scrollHeight="20rem"
                    selectionMode="multiple"
                    style="width:100%; font-size: 12px;"
                    @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                    @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"

                >
                  <Column :rowReorder="isEditable && srcOption != 'EXIST'" style="flex: 0 0 2rem"/>
                  <Column field="title"
                  >
                    <template #body="slotProps">
                      <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                      <i v-else class="pi pi-users p-mr-1"></i>
                      {{ slotProps.data.title }}
                    </template>
                  </Column>
                  <Column :selectionMode="(isEditable && srcOption != 'EXIST') ? 'multiple' : ''"
                          style="flex: 0 0 2rem"/>
                </DataTable>
              </div>
            </div>
          </template>
        </Card>
        <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
             style="display: inline-block;">
          <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                  icon="pi pi-plus" @click="newCallGroupDialog = true"/>
        </div>
      </div>
      <div v-else>
        <p>{{ _t('label_No_target_groups') }}</p>
        <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
             style="display: inline-block;">
          <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                  icon="pi pi-plus" @click="newCallGroupDialog = true"/>
        </div>
      </div>
    </TabPanel>
    <TabPanel :header="_t('label_Simplified_view')">
      <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
           class="p-grid">
        <Card
            v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
            :key="callGroup.id"
            class="p-mr-2 p-mb-2"
            style="display:inline-block; width:20rem; font-size: 12px;"
        >
          <template #content>
            <div class="p-grid">
              <div class="p-col-9">
                <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi-pencil"></i>
                      </span>
                  <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                             :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                </div>
                <p v-else class="color-green"><strong>{{
                    selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ? selectedAlarmPlan.callGroups[index].name : '[No title]'
                  }}</strong></p>
              </div>
              <div class="p-col-3 p-text-right">
                <Button v-if="isEditable && srcOption != 'EXIST'" class="p-button-danger"
                        @click="initCallGroupDeleteDialog(index)">
                  <i class="pi pi-trash"></i>
                </Button>
              </div>
              <div v-if="isEditable && srcOption != 'EXIST'" class="p-col-12">
                <p><strong>{{ _t('label_select_notification_method') }}</strong></p>
                <div v-for="(rule, i) in callRulesSimple"
                     :key="rule.value"
                     :class="['p-field-radiobutton', (rule.value == 'OTHER' ? 'hidden' : '')]">
                  <RadioButton :id="'call_rule'+i" v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                               :disabled="!isEditable"
                               :value="rule.value"
                               name="callRule"
                               @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                  <label :for="'call_rule'+i">{{ rule.label }}</label>
                </div>
              </div>
              <div v-else class="p-col-12">
                <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                :options="callRulesSimple"
                                optionLabel="label"
                                optionValue="value"/>
              </div>
<!--              <div v-if="isEditable && srcOption != 'EXIST'" class="p-col-8">-->
<!--                <span>{{ _t('label_Number_required_ack') }}</span>-->
<!--              </div>-->
<!--              <div v-if="isEditable && srcOption != 'EXIST'" class="p-col-4 p-text-right">-->
<!--                <InputText v-model="selectedAlarmPlan.callGroups[index].atLeastCount"-->
<!--                           class="p-m-0" min="0"-->
<!--                           style="width: 100%;" type="number"/>-->
<!--              </div>-->
<!--              <div v-else class="p-col-12">-->
<!--                <p>{{ _t('label_Number_required_ack') }} <strong-->
<!--                    style="float:right;">{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="p-col-8" style="vertical-align: middle;">-->
<!--                <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>-->
<!--              </div>-->
<!--              <div class="p-col-4 p-text-right">-->
<!--                  <span v-if="isEditable && srcOption != 'EXIST'" class="p-buttonset">-->
<!--                    <Button v-tooltip.bottom="_t('Edit')"-->
<!--                            class="p-button-success p-button-icon-only p-button-text"-->
<!--                            @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index], true)">-->
<!--                      <i class="pi pi-plus-circle"></i>-->
<!--                    </Button>-->
<!--                    <Button-->
<!--                        v-tooltip.bottom="_t('Delete')"-->
<!--                        :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"-->
<!--                        class="p-button-danger p-button-icon-only p-button-text"-->
<!--                        @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index], true)">-->
<!--                      <i class="pi pi-minus-circle"></i>-->
<!--                    </Button>-->
<!--                  </span>-->
<!--              </div>-->
<!--              <div class="p-col-12">-->
<!--                <DataTable-->
<!--                    v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"-->
<!--                    :disabled="!isEditable || srcOption == 'EXIST'" :scrollable="true"-->
<!--                    :value="callGroup.members"-->
<!--                    class="p-m-0 condensed"-->
<!--                    dataKey="id"-->
<!--                    scrollHeight="20rem"-->
<!--                    selectionMode="multiple"-->
<!--                    style="width:100%; font-size: 12px;"-->
<!--                    @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"-->
<!--                    @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"-->
<!--                >-->
<!--                  <Column :rowReorder="isEditable && srcOption != 'EXIST'" style="flex: 0 0 2rem"/>-->
<!--                  <Column field="title"-->
<!--                  >-->
<!--                    <template #body="slotProps">-->
<!--                      <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>-->
<!--                      <i v-else class="pi pi-users p-mr-1"></i>-->
<!--                      {{ slotProps.data.title }}-->
<!--                    </template>-->
<!--                  </Column>-->
<!--                  <Column :selectionMode="(isEditable && srcOption != 'EXIST') ? 'multiple' : ''"-->
<!--                          style="flex: 0 0 2rem"/>-->
<!--                </DataTable>-->
<!--              </div>-->
            </div>
          </template>
        </Card>
        <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
             style="display: inline-block;">
          <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                  icon="pi pi-plus" @click="newCallGroupDialog = true"/>
        </div>
      </div>
      <div v-else>
        <p>{{ _t('label_No_target_groups') }}</p>
        <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
             style="display: inline-block;">
          <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                  icon="pi pi-plus" @click="newCallGroupDialog = true"/>
        </div>
      </div>
    </TabPanel>
  </TabView>

  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_group_name')" class="p-m-0" style="width: 100%;"/>
    </div>
    <div class="p-mt-2">
      <p>{{ _t('label_organization') }}:
        <OrganizationLink v-model="selectedAlarmPlan" :editMode="false"/>
      </p>
      <p>{{ _t('label_alarm_plan') }}: <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} <strong class="color-green">{{
        originalCallGroup.name
      }}</strong></p>
    <ParticipantSelector v-model="editCallGroup.members" scrollHeight="40vh" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name && selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ? selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}:</strong>{{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>
</template>

<script>
import router from '@/router'
import SelectedOption from '@/components/ixarma/SelectedOption'
import OrganizationLink from '@/components/ixarma/OrganizationLink'
// import {getList} from '@/api/list'
import {createAlarmPlan, getAlarmPlan, updateAlarmPlan} from '@/api/alarmPlan'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'follow-up-participants-tab',
  components: {
    SelectedOption,
    OrganizationLink,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selectedAlarmPlan: {
      id: null
    },
    activeTabSimple: 0,
    srcOptions: [],
    srcOption: 'EXIST',
    newCallGroupDialog: false,
    newCallGroup: {},
    editCallGroupDialog: false,
    originalCallGroup: null,
    editCallGroup: {
      participants: [],
      participantGroups: [],
    },
    deleteCallGroupDialog: false,
    deleteCallGroupIndex: -1,
    participants: [],
    participantGroups: [],
    participantFilterValue: '',
    treeParams: '',
    selectedCallGroupMembers: {},
    callRules: [],
    callRulesSimple: [],
    selectRules: [],
  }),
  created() {
    this.callAlarmPlans();
  },
  mounted() {
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.srcOptions = [
      // {value: 'EXIST', label: this._t('label_Selected_plan')},
      // {value: 'NEW', label: this._t('label_Define_new_plan')},
      // {value: 'SIMPLE', label: this._t('label_Simplified_entry')},
    ]
  },
  methods: {
    ...mapActions({
      'callAlarmPlans': 'consequentials/callAlarmPlans',
    }),
    syncSrcOptions() {
      switch (this.srcOption) {
        case 'EXIST':
          break
        case 'NEW':
          this.createAlarmPlan()
          break
        case 'SIMPLE':
          this.createAlarmPlan(true)
          this.activeTabSimple = 0
          break
      }
    },
    createAlarmPlan(simple = false) {
      this.selectedAlarmPlan = {
        id: null,
        name: simple ? 'Participant plan for ' + this.getTrigger.alarmPointId : '',
        description: simple ? 'Automatically generated participant plan for ' + this.getTrigger.name : '',
        orgId: this.getTrigger.orgId,
        orgName: this.getTrigger.orgName,
        callGroups: []
      }

      this.newCallGroup.name = this._t('label_default')
      this.createCallGroup()
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    getAlarmPlan(id) {
      getAlarmPlan(id).then((response) => {
        this.selectedAlarmPlan = response.data
        this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
          if (callGroup.isRandom == true && callGroup.isEscalation == false) {
            callGroup.memberSelectRule = 'RANDOM'
          } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
            callGroup.memberSelectRule = 'ESCALATION'
          } else {
            callGroup.memberSelectRule = 'NONE'
          }
          this.syncGroupMemberCallRule(callGroup)
        }, this)
      })
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    toMember(participantId, participantGroupId) {
      if (this.isEditable) {
        return
      }

      if (participantId) {
        router.push(`/participants/${participantId}`)
        return
      }

      if (participantGroupId) {
        router.push(`/participant-groups/${participantGroupId}`)

      }
    },
    onSave() {
      if (this.srcOption !== 'EXIST') {
        if (this.selectedAlarmPlan !== null && this.selectedAlarmPlan.callGroups && this.selectedAlarmPlan.callGroups.length > 0) {
          this.saveAlarmPlan()
          return true
        } else {
          this.$root.showMessage('Please, select alarm participants.', 'error')
          return false
        }
      }
      return true
    },
    saveAlarmPlan() {
      let data = Object.assign({}, this.selectedAlarmPlan)
      if (data !== null && data.callGroups && data.callGroups.length > 0) {
        data.callGroups.forEach(function (group) {
          if (group.id && group.id.startsWith('_')) {
            delete group.id
          }
          if (group.alarmPlanId == null) {
            delete group.alarmPlanId
          }

          delete group.memberSelectRule
          delete group.memberCallRuleSimple

          group.members.forEach(function (member) {
            if (member.id && member.id.startsWith('_')) {
              delete member.id
            }
          })
        })

        if (data.id != null && !data.id.startsWith('_')) {
          updateAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)

            // this.saveAlarmPoint()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        } else {
          delete data.id
          createAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)
            this.getTrigger.alarmPlanId = response.data.id

            // this.saveAlarmPoint()
            this.getAlarmPlans()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        }
        this.activeTabSimple = 0
        this.$emit('update:isEditable', false);
      }

    },
  },
  computed: {
    ...mapGetters({
      'getTrigger': 'consequentials/getTrigger',
      'getAlarmPlans': 'consequentials/getAlarmPlans',
    }),
  },
  watch: {
    getTrigger: function () {
      if (this.getTrigger.alarmPlanId) {
        this.srcOption = 'EXIST';
        this.getAlarmPlan(this.getTrigger.alarmPlanId)
      }
    }
  }
}
</script>
