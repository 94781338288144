<template>
  <div class="p-grid">
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_Trigger_event') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <div v-if="isEditable" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-key"></i>
        </span>
        <Dropdown
            v-model="getTrigger.consequentialTrigger"
            :options="getAvailableTriggers"
            :placeholder="_t('label_triggering')"
            class="p-m-0"
            style="width:100%;"
            @change="this.callAlias(getTrigger.consequentialTrigger)"
        >
          <template #option="slotProps">
            {{ _t(slotProps.option) }}
          </template>
          <template #value="slotProps">
            {{ _t(slotProps.value) }}
          </template>
        </Dropdown>
      </div>
      <p v-else><strong>{{ getTrigger.consequentialTrigger }}</strong></p>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_Alarm_name') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <div v-if="isEditable">
        <InputText
            v-model="getTrigger.name"
            :placeholder="_t('label_Alarm_name')"
            class="p-m-0" disabled
            style="width:100%;"
        />
      </div>
      <p v-else><strong>{{ getTrigger.name }}</strong></p>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <Dropdown
          v-if="isEditable"
          v-model="getTrigger.confirmPriority"
          :options="[1,2,3,4,5,6,7,8,9,10]"
          class="p-m-0"
          style="width: 100%;"/>
      <p v-else><strong>{{ getTrigger.confirmPriority }}</strong></p>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>
      <div v-if="isEditable" class="p-inputgroup p-mt-5">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <Dropdown
            v-model="selectedTextVar"
            :disabled="!isEditable"
            :options="textVars"
            :placeholder="_t('select_predefined_variable')"
            class="p-m-0"
            style="width: 100%;"
            @change="getTrigger.text += selectedTextVar"
        />
      </div>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Textarea
          v-if="isEditable"
          v-model="getTrigger.text"
          :placeholder="_t('label_alarm_text')"
          class="p-m-0"
          rows="5"
          style="width:100%"
      />
      <p v-else><strong>{{ getTrigger.text }}</strong></p>
      <br/>
      <small v-if="alarmPointError.includes('getTrigger.text')" class="p-error">Text is required.</small>
    </div>
    <div class="p-col-12">
      <h3 class="subhead p-mt-4">&nbsp;</h3>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_description') }}</p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Textarea
          v-if="isEditable"
          v-model="getTrigger.description"
          :placeholder="_t('label_description')"
          class="p-m-0"
          rows="5"
          style="width:100%"/>
      <p v-else><strong>{{ getTrigger.description ?? '...' }}</strong></p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'follow-up-basics-tab',
  components: {},
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selectedTextVar: null,
    textVars: [
      '<original>', '<K>', '<G>', '<family>', '<callerid>'
    ],
    alarmPointError: [],
  }),
  mounted() {
  },
  methods: {
    ...mapActions({
      'callAlias': 'consequentials/callAlias',
    }),
  },
  computed: {
    ...mapGetters({
      'getTrigger': 'consequentials/getTrigger',
      'getAvailableTriggers': 'consequentials/getAvailableTriggers',
    }),
  }
}
</script>
